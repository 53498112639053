
export default function guideModal() {
    return {
        open: false,
        init() {
            
        },
        showModal() {
            this.open = true;
        },
        hideModal() {
            this.open = false;
        }
    }
 }