import './bootstrap';
import guideModal from './components/guideModal';
import.meta.glob([
    '../images/**/*.{png,jpg,jpeg,gif,webp,svg}',
]);

(function() {
    if (typeof window === 'undefined') {
      return;
    }
  
    try {
      var ce = new window.CustomEvent('test', { cancelable: true });
      ce.preventDefault();
      if (ce.defaultPrevented !== true) {
        // IE has problems with .preventDefault() on custom events
        // http://stackoverflow.com/questions/23349191
        throw new Error('Could not prevent default');
      }
    } catch (e) {
      var CustomEvent = function(event, params) {
        var evt, origPrevent;
  
        // We use here some version of `Object.assign` implementation, to create a shallow copy of `params`.
        // Based on https://github.com/christiansany/object-assign-polyfill/blob/213cc63df14515fb543117059d1576204bfaa8a7/index.js
        var newParams = {};
        // Skip over if undefined or null
        if (params != null) {
          for (var nextKey in params) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(params, nextKey)) {
              newParams[nextKey] = params[nextKey];
            }
          }
        }
  
        newParams.bubbles = !!newParams.bubbles;
        newParams.cancelable = !!newParams.cancelable;
  
        evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(
          event,
          newParams.bubbles,
          newParams.cancelable,
          newParams.detail
        );
        origPrevent = evt.preventDefault;
        evt.preventDefault = function() {
          origPrevent.call(this);
          try {
            Object.defineProperty(this, 'defaultPrevented', {
              get: function() {
                return true;
              }
            });
          } catch (e) {
            this.defaultPrevented = true;
          }
        };
        return evt;
      };
  
      CustomEvent.prototype = window.Event.prototype;
      window.CustomEvent = CustomEvent; // expose definition to window
    }
  })();

// import * as Sentry from "@sentry/browser";

// Sentry.init({
//   dsn: "https://b0e2bebc4e7739a6584ce664073b5a33@o423412.ingest.us.sentry.io/4507923879034880",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

Alpine.data('guideModal', guideModal);
